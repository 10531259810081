import axios from "@/utils/request";
export async function getList() {
   const res = await axios({
        method: 'post',
        url: 'https://api.dailyhistory.online/history/list',
        data: {
            webIndex: 'odd'
        },
        
    })
    return res.data
}

