var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CommonPage',[_c('div',{staticClass:"index"},[_c('Wrapper',{attrs:{"color":"black"}},[_c('div',{staticClass:"top"},[_c('h1',[_vm._v("This Day In History")]),_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(`${_vm.month} | ${_vm.day}`)+" ")])]),_c('div',{staticClass:"pointer",style:({ background: `url(${_vm.curOne?.img}) no-repeat center center / cover, linear-gradient(to bottom, rgba(255,255,255,0.5), rgba(0,0,0,0.9))` }),attrs:{"id":"banner"},on:{"click":function($event){return _vm.$router.push({ name: 'detail', params: { id: _vm.curOne?.id } })}}},[_c('div',{staticStyle:{"height":"25vh"}}),_c('div',{staticClass:"year"},[_vm._v(_vm._s(_vm.curOne?.year))]),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.curOne?.title))]),(!_vm.isPC)?_c('Ad',{staticStyle:{"width":"100%"},attrs:{"ads":_vm.adsensConfig.ad1}}):_vm._e(),_c('div',{staticClass:"more"},[_vm._v("Read more")])],1),_c('div',{staticClass:"b"},[_c('div',{staticClass:"son"},[_c('div',{staticClass:"text"},[_vm._v("Also on This Day in History")]),_c('div',{staticClass:"hs"},[_vm._l((_vm.$store.state.list),function(item){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(item.id !== _vm.curOne.id),expression:"item.id !== curOne.id"}],key:item?.title,staticClass:"itemBlock pointer",on:{"click":function($event){return _vm.$router.push({ name: 'detail', params: { id: item?.id } })}}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item?.img),expression:"item?.img"}],attrs:{"alt":""}}),_c('div',{staticClass:"title"},[_c('div',{staticClass:"num"},[_vm._v(_vm._s(item?.year))]),_vm._v(" "+_vm._s(item?.title)+" ")])])}),_c('Ad',{attrs:{"ads":_vm.adsensConfig.ad3,"posi":{
								pc: {
									rowAt: 3,
									colNum: 3,
								},
								m: 3,
							}}}),_c('Ad',{attrs:{"ads":_vm.adsensConfig.ad2,"posi":{
								pc: {
									rowAt: 7,
									colNum: 3,
								},
								m: 7,
							}}})],2)])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }